import React from "react";
import { Helmet } from "react-helmet-async";
import OurTeam from "./../components/ourTeam/OurTeam";

const OurTeamPage = () => {
  return (
    <>
      <Helmet>
        <title>Our Team | think thing thank</title>
      </Helmet>
      <OurTeam />
    </>
  );
};

export default OurTeamPage;
