import React from "react";
import { Helmet } from "react-helmet-async";
import WorkView from "./../components/workView/WorkView";

const WorkViewPage = () => {
  return (
    <>
      <Helmet>
        <title>Work View | think thing thank</title>
      </Helmet>
      <WorkView />
    </>
  );
};

export default WorkViewPage;
