import React from "react";
import { Helmet } from "react-helmet-async";
import WorkCreative from "../components/workCreative/WorkCreative";

const WorkCreativePage = () => {
  return (
    <>
      <Helmet>
        <title>Work Creative | think thing thank</title>
      </Helmet>
      <WorkCreative />
    </>
  );
};

export default WorkCreativePage;
