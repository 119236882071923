import React from "react";
import { Helmet } from "react-helmet-async";
import ConfirmMessage from "../components/confirmMessage/ConfirmMessage";

const ConfirmMessagePage = () => {
  return (
    <>
      <Helmet>
        <title>Confirm MessagePage | think thing thank</title>
      </Helmet>
      <ConfirmMessage />
    </>
  );
};

export default ConfirmMessagePage;
