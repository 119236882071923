import React from "react";
import { Helmet } from "react-helmet-async";
import Work from "./../components/work/Work";

const WorkPage = () => {
  return (
    <>
      <Helmet>
        <title>Work | think thing thank</title>
      </Helmet>
      <Work />
    </>
  );
};

export default WorkPage;
