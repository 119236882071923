import React from "react";
import { Helmet } from "react-helmet-async";
import About from "./../components/about/About";

const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>About | think thing thank</title>
      </Helmet>
      <About />
    </>
  );
};

export default AboutPage;
