import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import NextProject from "../common/NextProject/NextProject";
import "./workView.scss";
import { useParams } from "react-router";
import { DelayLink } from "./../common/Header/DelayLink";
import Footer from "./../common/Footer/Footer";
import e from "cors";

const apiPath = "https://admin.thinkthingthank.com/api";

const WorkView = () => {
  const [portData, setPortData] = useState([]);
  const [memArr, setMemArr] = useState([]);
  const [prevData, setPrevData] = useState([]);
  const [nextData, setNextData] = useState([]);
  const params = useParams();

  useEffect(() => {
    const getPosts = async () => {
      var form = new FormData();
      form.append("idx", params.id);

      const response = await axios.post(apiPath + "/getPortfolioData", form);
      var data = response.data;
      setPortData(response.data.portData);
      setMemArr(response.data.memArr);
      setPrevData(response.data.prevData);
      setNextData(response.data.nextData);
    };

    getPosts();
  }, [params.id]);

  return (
    <div
      className="work-view"
      onContextMenu={(e) => e.preventDefault()}
      onKeyDown={(e) => {
        if (e.key === "F12") {
          e.preventDefault();
          e.returnValue = false;
        }
      }}
    >
      <div className="scroll-container">
        <div className="sec1">
          <h1 className="title">
            <div className="inner ani">
              <h1>
                <span className="ko" dangerouslySetInnerHTML={{ __html: portData.title }}></span>
              </h1>
            </div>
          </h1>
          {portData.link_url !== null && portData.link_url !== "" && portData.link_url !== undefined ? (
            <div className="video-box ani">
              <div className="video">
                <iframe
                  src={`${portData.link_url}&controls=0&autopause=0&background=1`}
                  title="work_view1"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          ) : (
            <div className="img-box ani">
              <img src={`https://admin.thinkthingthank.com${portData.top_img}`} alt="" />
            </div>
          )}
        </div>
        <div className="sec2">
          <div className="inner">
            <div className="detail step1 line-top ani">
              <div className="detail-lists ani">
                <ul>
                  <li>
                    <span>
                      Clients : <span className="clients active">{portData.client}</span>
                    </span>
                  </li>
                  <li>
                    <span>
                      Location : <span className="location active">{portData.location}</span>
                    </span>
                  </li>
                  <li>
                    <span>
                      Category : <span className="category active">{portData.cate_text}</span>
                    </span>
                  </li>
                  <li>
                    <span>
                      Year : <span className="year active">{portData.year}</span>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="description">
                <p className="ani2">
                  <span dangerouslySetInnerHTML={{ __html: portData.intro_desc }}></span>
                </p>
              </div>
            </div>
            <div className="detail step2 line-top ani">
              <div className="ttt line-bottom ani">
                <span className="img-box">
                  {portData.agency !== "" && portData.agency !== null ? (
                    <span>{portData.agency}</span>
                  ) : (
                    <img src="../images/work_view_logo.png" alt="think thing thank" />
                  )}
                </span>
                {portData.with !== "" && portData.with !== null ? (
                  <div className="txt">
                    <span>
                      with <span className="with">{portData.with}</span>
                    </span>
                  </div>
                ) : (
                  <div className="txt"></div>
                )}
              </div>
              <div className="member-lists ">
                <ul className="member-lists-ul">
                  {memArr.map((item, index) => (
                  <li key={index} className="line-bottom ani">
                    <div className="wrap">
                      <span className="field">{item.bunya}</span>
                      <ul className="name-list-ul">
                        {item.member.map((memItem, memIndex) => (
                          <li key={memIndex}>
                            <button className="invert-btn">
                              <span className="btn-wrap">
                                <DelayLink to={`/our-team/${memItem}`}>{memItem}</DelayLink>
                              </span>
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="sec3" dangerouslySetInnerHTML={{ __html: portData.content }}></div>
        <NextProject title="Next" />
        <Footer />
      </div>
    </div>
  );
};

export default WorkView;
