import "./footer.scss";
import { DelayLink } from "./../Header/DelayLink";

const Footer = () => {
  return (
    <footer>
      <div className="inner">
        <div className="work-with-us line-top line-bottom ani">
          <DelayLink to="../contact" className="arrow-txt ani">
            <span>
              Work with <i>us</i>!
              <span className="arrow">
                <span className="step1"></span>
                <span className="step2"></span>
              </span>
            </span>
          </DelayLink>
        </div>
        <div className="info-box">
          <div className="left-box">
            <ul>
              <li>
                14, Yangpyeong-ro 30-gil,
                <br />
                Yeongdeungpo-gu,
                <br />
                Seoul, Republic of Korea (07202)
              </li>
              <li>
                Tel.
                <br />
                02 2677 0110
              </li>
              <li>
                Mail.
                <br />
                think.thing.thank@gmail.com
              </li>
            </ul>
          </div>
          <div className="right-box">
            <a href="https://admin.thinkthingthank.com/partners/index" target="_blank" rel="noopener noreferrer" className="admin-link arrow-txt">
              (P<i>art</i>ner Admin
              <span className="arrow">
                <span className="step1"></span>
                <span className="step2"></span>
              </span>
              )
            </a>
            <img src={`${process.env.PUBLIC_URL}/images/footer_logo.png`} alt="think thing thank" className="f-logo-b" />
            <img src={`${process.env.PUBLIC_URL}/images/footer_logo_w.png`} alt="think thing thank" className="f-logo-w" />
            <p>© think thing thank. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
